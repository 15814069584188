import "maplibre-gl/dist/maplibre-gl.css"

import { useEffect, useState, useRef } from "react"
import { useRouter } from "next/router"
import { getPlainText } from "utilities/getPlainText"
import { useIntersectionObserver } from "usehooks-ts"

export default function Map({
  // default center is Roosevelt Arizona
  lng: initialLng = -111.134153997,
  lat: initialLat = 33.6672587976,
  initialZoom = 6,
  locations = [],
  scrollToZoom = true,
  flyTo,
}) {
  const router = useRouter()

  const mapContainer = useRef(null)
  const map = useRef(null)

  const [lng] = useState(initialLng)
  const [lat] = useState(initialLat)
  const [markers, setMarkers] = useState([])

  const currentLocation = locations.find(({ name }) => name === flyTo)

  const entry = useIntersectionObserver(mapContainer, {})
  const isVisible = !!entry?.isIntersecting

  // initialize map (warning: fair amount of imperative code below)
  useEffect(() => {
    if (map.current) return

    async function loadMap() {
      const maplibregl = (await import("maplibre-gl")).default
      const layers = (await import("components/mapLayersStyles")).default

      map.current = new maplibregl.Map({
        container: mapContainer.current,
        center: [lng, lat],
        minPitch: 0,
        minZoom: 6,
        maxZoom: 15,
        pitch: 0,
        bearing: 20,
        style: {
          version: 8,
          glyphs: "/gis/glyphs/{fontstack}/{range}.pbf",
          sources: {
            protomaps: {
              type: "vector",
              tiles: ["https://maps.2060.services/basemapsz15/{z}/{x}/{y}.mvt"],
              attribution: `Sonoran Spine© <a href="https://openstreetmap.org">OpenStreetMap Contributors</a>`,
            },
          },
          layers,
        },
      })

      // markers/locations & popups
      const markers = locations.map(({ coords, name, address, slug }) => {
        const mapPin = document.createElement("div")
        mapPin.innerHTML =
          '<svg class="map-pin" width="22" height="34" viewBox="0 0 22 34" fill="none" xmlns="http://www.w3.org/2000/svg"><ellipse cx="11.1291" cy="11.1412" rx="6.41667" ry="6.25862" fill="#B0D9FF"/><path d="M16.787 2.23705C15.1581 1.03675 13.1774 0.369239 11.1308 0.330811C9.08236 0.369521 7.09982 1.03883 5.47007 2.24121C-0.181862 6.2062 -0.938582 13.7298 3.48386 18.8428C10.9205 27.4379 11.1262 33.2461 11.1305 33.3308C11.1305 33.2333 11.3185 27.4383 18.764 18.8473C23.1959 13.7343 22.4477 6.20211 16.787 2.23743V2.23705ZM11.1308 16.2969C9.67862 16.2969 8.28564 15.7384 7.25856 14.7441C6.23147 13.7497 5.65442 12.401 5.6538 10.9945C5.65349 9.58825 6.22991 8.2393 7.25645 7.24438C8.28299 6.24947 9.67566 5.69006 11.1279 5.68945C12.5804 5.68856 13.9737 6.24647 15.0011 7.24053C16.0288 8.23431 16.6068 9.58265 16.6079 10.9889C16.6088 12.3964 16.0324 13.7465 15.0053 14.742C13.9779 15.7376 12.5843 16.297 11.1308 16.297V16.2969Z" fill="#72226D"/></svg>'

        const plaintextAddress = getPlainText(address)

        const popupBody = document.createElement("div")
        popupBody.innerHTML = `
        <h5 class="location-title text-m1 lg:text-m2 pb-2 lg:pb-4">${name}</h5>
        <address class="text-s2 pb-8 pr-8">${plaintextAddress}</address>
        <button class="bg-purple absolute bottom-0 right-0 p-4"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="8" fill="none"><path fill="white" d="M15.27 4.35a.5.5 0 0 0 0-.7L12.09.46a.5.5 0 1 0-.71.71L14.2 4l-2.83 2.83a.5.5 0 1 0 .7.7l3.19-3.18ZM.42 4.5h14.5v-1H.41v1Z"/></svg></button>`

        popupBody.querySelector("button").addEventListener("click", () => router.push(slug))

        const popup = new maplibregl.Popup({
          offset: {
            bottom: [0, -34],
          },
          closeOnMove: true,
          closeButton: false,
          focusAfterOpen: false,
          maxWidth: "280px",
          anchor: "bottom",
        }).setDOMContent(popupBody)

        const marker = new maplibregl.Marker({
          element: mapPin,
          anchor: "bottom",
        })
          .setLngLat(coords)
          .setPopup(popup)
          .addTo(map.current)

        mapPin.addEventListener("click", () => {
          map.current.flyTo({ center: [coords[0], coords[1] + 0.0012], zoom: 15, speed: 1.7 })
          map.current.once("moveend", () => marker.togglePopup())
        })

        return marker
      })
      // toggled after location traversal below
      setMarkers(markers)

      // geolocation (find nearest location)
      const geolocate = new maplibregl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
      })

      geolocate.on("geolocate", async function ({ coords }) {
        const distance = (await import("@turf/distance")).default
        const { point } = await import("@turf/helpers")

        const nearestLocations = locations
          .map(({ coords: locationCoords, slug, ...rest }) => {
            const userLocation = point([coords.longitude, coords.latitude])
            const locationPoint = point(locationCoords)
            const distanceToLocation = +distance(userLocation, locationPoint, { units: "miles" }).toFixed(2)

            return {
              distanceToLocation,
              coords: locationCoords,
              slug,
              ...rest,
            }
          })
          .sort((a, b) => (a.distanceToLocation < b.distanceToLocation ? -1 : 1))

        const closest = nearestLocations[0]

        map.current.flyTo({ center: closest.coords, zoom: 15, speed: 1.5 })
      })

      // controls
      map.current.addControl(new maplibregl.NavigationControl({ visualizePitch: true }), "top-left")
      map.current.addControl(geolocate)

      map.current.on("load", () => {
        map.current.flyTo({ center: [lng, lat], pitch: 45, zoom: initialZoom, bearing: 0, duration: 1000 })
        if (mapContainer.current) {
          mapContainer.current.classList.add("loaded")
        }
      })

      if (!scrollToZoom) {
        map.current.scrollZoom.disable()
      }
    }

    if (isVisible) {
      loadMap()
    }
  }, [lat, isVisible, lng, initialZoom, locations, flyTo, router, scrollToZoom])

  useEffect(() => {
    if (currentLocation?.coords) {
      const marker = markers.find((marker) => {
        const markerCoords = Object.values(marker.getLngLat()).toString()
        const currentCoords = currentLocation.coords.map((v) => +v).toString()

        return markerCoords === currentCoords
      })

      map.current.flyTo({ center: currentLocation.coords, zoom: 15, speed: 1.5 })
      map.current.once("moveend", () => marker?.togglePopup())
    }
  }, [currentLocation, markers, flyTo])

  return <div ref={mapContainer} className="absolute w-full h-full inset-0 object-cover bg-[#f1e8d7]" />
}
