import richText from "utilities/richText"
import Wave from "public/assets/home-map-waves.svg"
import Map from "components/Map"

export default function HomepageMapSection({ blok }) {
  return (
    <section className="sm:mt-12">
      <Wave className="w-full h-full -mb-1 -scale-x-100 lg:scale-x-100" />
      <div className="bg-blue z-10 pt-12 lg:pt-6">
        <div className="flex flex-col lg:flex-row gap-4 md:gap-12 px-8 mx-auto max-w-screen-xl pb-12">
          <div className="lg:basis-5/12 prose-h2:text-m3 lg:prose-h2:text-l2 prose-h2:uppercase prose-h2:pb-2 prose-p:pb-2 prose-p:font-light prose-p:leading-7 lg:py-12">
            {richText(blok.content)}
          </div>
          <div className="lg:basis-7/12 relative h-80 sm:h-96 lg:h-auto">
            <Map initialZoom={6.2} locations={blok.locations} />
          </div>
        </div>
      </div>
    </section>
  )
}
